import React, { useEffect, useState, useRef } from "react";

import "../Main.css";
import logo from '../images/logo-new.png'; // with import

import apeclub from '../images/Hongbao.gif'

import { useDispatch, useSelector } from "react-redux";



import { connect } from "../redux/blockchain/blockchainActions.js";
import { fetchData } from "../redux/data/dataActions";


import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";

export const StyledButton = styled.button`

  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;




function Main() {
  
  const titleRef = useRef()
  const teamRef = useRef()
  
  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
}

function handleTeamClick() {
  teamRef.current.scrollIntoView({ behavior: 'smooth' })
}
    
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [amount, setAmount] = useState(1)

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Lunar Tiger NFT...");
    setClaimingNft(true);
    try {
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: "285000",
        to: "0x18d250c3d31d56bcc2f52baad0d5d8eb8d496c04",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.088 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Success!"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      }); 
    } catch (err) {
      window.alert('Please Connect Your Wallet First!');
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  
  
  
  
  return (
      <div>
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration={400}
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navigation w-nav"
        >
          <div className="navigation-wrap">
            <a
              href="/"
              aria-current="page"
              className="logo-link w-nav-brand w--current"
              aria-label="home"
            >
              <img
                src={logo}
                width={108}
                alt
                className="logo-image"
              />
            </a>

            <a
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
              className="button cc-contact-us w-inline-block"
            >
              <div className="top-button">
                <img
                  src="https://global-uploads.webflow.com/6153e8f1cbd0aea7e06bd871/615a45006369de5e7e1eef1e_61457.png"
                  loading="lazy"
                  alt
                  className="image-9"
                />
                <div className="text-block-25">
                
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container>
                    <s.SpacerSmall />
                    <div class="connect_wallet_button" 
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(connect());
                                        getData();
                                      }}>
                CONNECT WALLET
                </div>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "right" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"right"} jc={"right"} fd={"row"}>

                      {claimingNft ? "BUSY" : "You are now connected!"}
                    
                  </s.Container>
                )}
                </div>
              </div>
            </a>
          </div>
          <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
        </div>
        <div className="home-section wf-section">
          <div className="intro-header">
            <div className="intro-content cc-homepage">
              <div className="w-layout-grid home-grid">
                <div className="div-block">
                  <div className="heading-jumbo">
                    Welcome to the 
                    <br />
                    Lunar Tiger NFT
                  </div>
                  <div className="paragraph-bigger cc-bigger-white-light">
                    The 紅包 (Hongbao) NFT genesis collection is a collection of{" "}
                    <strong>8,888 Lunar Tiger NFTs.</strong>Each Lunar Tiger NFT is a unique creation with different traits, 
					ranging from blessings, eyes, mouths, and stripe colors. No two are the same, all live happily on the Ethereum blockchain.<br />
                  </div>
                  <h1 className="heading-jumbo">{data.totalSupply} / 8888</h1>
                  <div className="amountButtons">
                  <button className="plus" onClick={() => {if (amount<20) {setAmount(amount+1)}}}>+</button>
                  <h2 className="amount">{amount}</h2>
                  <button className="minus" onClick={() => {if (amount>=2) {setAmount(amount-1)}}}>-</button>
                  </div>
                  <div className="button-wrapper">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(amount);
                      }}
                      className="button cc-jumbo-button-one cc-jumbo-white w-inline-block"
                    >
                      <div>
                      </div>
                      <div className="bottom-button">
                        <img
                          src="https://global-uploads.webflow.com/6153e8f1cbd0aea7e06bd871/615a45006369de5e7e1eef1e_61457.png"
                          loading="lazy"
                          alt
                          className="image-10"
                        />
                        <div className="text-block-22" >MINT </div>
                      </div>
                    </a>
                    <a
                      href="https://opensea.io/collection/lunar-tiger-nft"
                      target="_blank"
                      className="button cc-jumbo-button-one-copy cc-jumbo-white w-inline-block"
                    >
                      <div className="button-div">
                        <img
                          src="https://global-uploads.webflow.com/6153e8f1cbd0aea7e06bd871/61540bd4799bbe452b0e1128_discord-v1.png"
                          loading="lazy"
                          alt
                          className="image-2"
                        />
                        <div className="text-block-23">OPENSEA</div>
                      </div>
                    </a>
                  </div>
                  <h1 className="paragraph-bigger cc-bigger-white-light">{feedback}</h1>

                </div>
                <div>
                  <img
                    src={apeclub}
                    loading="lazy"
                    width={161}
                    alt
                    className="image"
                  />
                </div>
              </div>
            </div>
          </div>
       </div>
       </div>
        
    );
  }


export default Main;
